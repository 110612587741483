.landing-upd-constructor {
    margin: 30px 100px 60px 100px;
    background-color: #B00017;
    display: flex;
    flex: 1;
}

.landing-upd-constructor-editor {
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-family: 'TT Drugs';
    width: 50%;
    padding: 30px 30px 0 30px;
}

.landing-upd-constructor-editor-title {
    color: white;
    font-size: 42px;
    margin-bottom: 40px;
}

.landing-upd-constructor-editor-box {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 20px;
}

.landing-upd-constructor-editor-box-title {
    color: white;
    font-size: 24px;
}

.landing-upd-constructor-editor-box-selector {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    gap: 12px;
}

.landing-upd-constructor-editor-box-item {
    background-color: white;
    height: 40px;
    flex: 1;
    object-fit: cover;
    aspect-ratio: 1/1;
}

.landing-upd-constructor-editor-box-input {
    background-color: transparent;
    border: 1px solid white;
    border-radius: 4px;
    color: white;
    padding: 12px;
    height: auto;
    width: auto;
}

.landing-upd-constructor-editor-box-textarea {
    background-color: transparent;
    border: 1px solid white;
    border-radius: 4px;
    color: white;
    padding: 12px;
    height: 200px;
    width: auto;
    font-family: 'TT Drugs';
}

.landing-upd-constructor-editor-box-input:focus, .landing-upd-constructor-editor-box-textarea:focus {
    outline: none;
    color: white;
}

.landing-upd-constructor-preview {
    width: 50%;
}

.preview-cover, .preview-content, .preview-photo {
    background-color: #D1D1D1;
    position: relative;
}

.preview-cover img, .preview-content img, .preview-photo img {
    max-width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
}

.preview-photo-uploaded {
    position: absolute;
    top: 27%;
    bottom: 27%;
    left: 50%;
    z-index: 1;
    width: 38%;
    height: 47%;
    max-width: 38%;
    max-height: 47%;
    object-fit: cover;
}

.preview-photo-main {
    z-index: 2;
    position: relative;
}

.preview-bookname, .preview-author, .preview-receiver, .preview-answer, .preview-description {
    position: absolute;
    z-index: 2;
}

.preview-bookname {
    color: white;
    font-family: 'Leotaro';
    line-height: 1;
    top: 28%;
    left: 37%;
    right: 37%;
    text-align: center;
    font-size: 24px;
}

.preview-author {
    color: white;
    font-family: 'TT Drugs';
    line-height: 1;
    top: 64.5%;
    left: 42.7%;
    right: 43.3%;
    text-align: center;
    font-size: 6px;
}

.preview-receiver {
    color: rgb(231, 231, 231);
    font-family: 'TT Drugs';
    line-height: 1;
    top: 66%;
    left: 44.5%;
    right: 44.5%;
    text-align: center;
    font-size: 5px;
}

.preview-answer {
    color: black;
    font-family: 'Playfair Display';
    line-height: 1;
    top: 30.69%;
    left: 17.74%;
    right: 56.5%;
    font-size: 12px;
}

.preview-description {
    color: black;
    font-family: 'Playfair Display';
    line-height: 1.3;
    top: 26.93%;
    left: 57.12%;
    right: 18.18%;
    font-size: 6px;
    text-align: center;
}

.navigation-buttons {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 5;
    display: flex;
    gap: 10px;
}

.navigation-button {
    flex: 1;
    padding: 12px 20px;
    border: none;
    color: white;
    font-size: 20px;
    font-size: 16px;
    cursor: pointer;
}

.navigation-button.back {
    background-color: black;
}

.navigation-button.next {
    background-color: #B00017;
}

.navigation-button.order {
    background-color: #05A000;
}

.navigation-buttons-mobile {
    display: none;
}

.landing-upd-constructor-editor-box label {
    color: white;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 4px;
    padding: 12px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 900px) {


    .landing-upd-constructor {
        flex-direction: column;
        margin: 0;
        padding-bottom: 100px;
    }

    .landing-upd-constructor-editor-title {
        line-height: 1;
    }

    .navigation-buttons-mobile {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 5;
        display: flex;
        width: 100%;
        gap: 0;
    }

    .navigation-buttons {
        display: none;
    }
    
    .navigation-button {
        flex: 1;
        padding: 20px;
        border: none;
        color: white;
        font-size: 20px;
    }

    .navigation-button.back {
        background-color: black;
    }

    .navigation-button.next {
        background-color: #B00017;
    }

    .navigation-button.order {
        background-color: #05A000;
    }

    .landing-upd-constructor-editor, .landing-upd-constructor-preview {
        width: auto;
    }

    .landing-upd-constructor-editor {
        order: 2;
        padding: 20px;
        gap: 0
    }

    .preview-answer {
        font-size: 9px;
    }

    .preview-bookname {
        font-size: 16px;
    }

    .preview-author {
        font-size: 5px;
    }

    .preview-receiver {
        font-size: 3.3px;
    }

    .preview-description {
        font-size: 5px;
    }
}